@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;900&family=Knewave&family=Montserrat:wght@900&display=swap');

html,
body {
	width: 100%;
	background-image: linear-gradient(
		to bottom,
		#324ff5 0%,
		#1e0e62 20%,
		#000000 80%
	);
}

@font-face {
	font-family: 'AgrandirBold';
	src: local('AgrandirBold'),url('./fonts/Agrandir-GrandHeavy.otf') format('opentype');
	font-weight: bold; 
	font-style: normal;
  }

body {
	font-family: 'DM Sans', sans-serif;
	overflow-x: hidden;
}

.text-agrandir-bold{
	font-family: 'AgrandirBold';
}

.text-knewave {
	font-family: 'Knewave';
}

.text-Montserrat {
	font-family: 'Montserrat';
}

.text-inter {
	font-family: 'Inter';
}

code {
	font-family: 'Inconsolata', monospace;
}

.general-padding {
	@apply md:px-10 px-5;
}

.no-scroll {
	overflow: hidden;
}

.wider-letters {
	font-stretch: expanded !important;
}
.download-btn {
	@apply flex shadow-xl h-12 lgTablet:h-14 items-center  text-white z-20 font-semibold rounded-2xl py-2 px-4 lgTablet:px-8 cursor-pointer transition-all duration-300 ease-in-out hover:brightness-125;
}

.text-shadow {
	text-shadow: 2px 2px 10px #2eb9ff; /* Horizontal, Vertical, Blur, Color */
}
